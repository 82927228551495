.payment-card{
  border: 1px solid #a1513b;
  border-radius: 5px;
  width: 50%;
}
.form-style{
  padding: 25px;
  display: flex;
  align-items: center;
}
.payment-label{
  font-size: 18px;
  color:#ff7f49 ;
}
.payment-input{
  border: 0;
  border-bottom: 1px solid #a1513b;
  padding: 5px 10px;
  background-color: #fff7f7;
}
