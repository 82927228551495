.desktop-header {
    box-shadow: 0px -5px 9px 7px #0000001c;
    padding: 5px;
}

.header-down-icon-container {
    padding: 0px 0px 0px 10px;
}

.menu-container {
    background: transparent;
    font-size: 15px;

}

#menu,
#menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

#menu {
    margin: 0px auto;
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
}

#menu:before,
#menu:after {
    content: "";
    display: table;
}

#menu:after {
    clear: both;
}

#menu li {
    float: left;
    width: 100%;

    position: relative;
    height: min-content;
    display: flex;
    align-items: flex-end;
}

#menu li.float-right {
    float: right !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

#menu a {
    float: left;
    padding: 12px 30px;
    /* font-family: "Open Sans", "Helvetica Neue", sans-serif; */
    font-family: 'Barlow';
    
    text-decoration: none;
    color: #fff;
}

#menu li.parent-menus>a {
    color: #222;
    /* margin: 0px 25px; */
    padding: 0px;
}

.parent-menus {
    display: flex;
    /* align-items: flex-end; */
    /* height: 70px; */
}

#menu li:hover>a {
    color: #fafafa;
    background: #262626;
}

#menu li.sub-menus:hover>a {
    color: #fafafa;
    background: #a1513b;
}

#menu li.parent-menus:hover>a {
    color: #a1513b;
    background: white;
}

#menu ul {
    margin: 0px 40px;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    border-top: 3px solid #a1513b;
    top: 30px;
    left: 0;
    z-index: 1;
    background: #f6f6f6;
    /* border-radius: 3px; */
    transition: all 0.3s ease-in-out;
}

#menu li:hover>ul {
    opacity: 1;
    visibility: visible;
    box-shadow: 0 -2px 20px 0px #4e464633;
    margin: 0px 0px 0px 40px;
}

#menu ul ul {
    top: 0;
    left: 220px;
    margin: 0px !important;
}

#menu ul li {
    float: none;
    display: block;
    border: 0;
}

#menu ul li:last-child {
    box-shadow: none;
}

#menu ul a {
    padding: 10px 5px 10px 20px;
    width: 220px;
    display: block;
    color: #111;
    float: none;
}

#menu ul a:hover {
    background: #a1513b;
}

#menu ul li:first-child>a::after {
    content: "";
    position: absolute;
    left: 40px;
    top: -6px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-right: 6px solid #a1513b;
}


/* #menu ul ul li:first-child a:after {
    border-bottom: 6px solid transparent;
    border-top: 6px solid transparent;
    border-right: 6px solid #a1513b;
    left: -6px;
    top: 50%;
    margin-top: -6px;
    border-left: 0;
} */

#menu ul li:first-child a:hover:after {
    border-bottom-color: #a1513b;
}

#menu ul ul li:first-child a:hover:after {
    border-right-color: #a1513b;
    border-bottom-color: transparent;
}

.kied-header-logo {
    width: 315px;
}

.kied_mobile_logo {
    width: 240px;
}

.header-govt-logo {
    height: 35px;
}

#header {
    top: 0;
    position: fixed;
    height: 115px;
    z-index: 2000;
    width: 100%;
    background: white;
    transition: all 0.5s ease-out;
}

#flex-header {
    /* padding: 15px 15px; */
    padding-top: 5px;
    transition: all 0.5s ease-in;
}

#partners-section.container-lg,
.hero-overlay>.container-lg {
    max-width: 1800px;
}

#flex-header.sticky {
    /* position: sticky; */
    padding-top: 5px;
    padding: 15px 0px;
    /* padding-top: 30px; */
    /* height: 155px; */
    transition: all 0.5s ease-in;
}


/* /////////////////////////////// */


/* ////////////////////////////////////////////// */

.responsive-menu-button {
    background: #a1513b;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-size: 30px;
    border-radius: 5px;
    color: white;
}

.main-menu-bg {
    background: #a1513b;
    color: white;
    padding: 10px;
    border: 1px solid white;
}

.menu-bg {
    /* background: #a1513b; */
    color: white;
    padding: 10px;
    /* border: 1px solid white; */
}

.sub-menu-bg {
    /* background: #a1513b; */
    color: white;
    padding: 10px;
    /* border: 1px solid white; */
}

.menu-expand-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    border: 1px solid white;
    padding: 5px;
    border-radius: 5px;
}

.header-font-mob {
    font-size: 35px;
}

.menu-ul-padding {
    padding: 10px 20px;
}

#mobile-header {
    position: fixed;
    top: 0;
    z-index: 200000;
    padding-top: 20px;
    background: white;
    width: 100%;
}

.mob-menu-ul {
    max-height: calc(100vh - 180px);
    overflow: auto;
}

.mobile_login_button {
    padding: 3px 10px 3px;
    margin-top: 1px;
    background-color: #fff;
    color: #a1513b;
    border: 0;
    font-size: 18px;
}