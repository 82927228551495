.carousel-container.single>button.react-multiple-carousel__arrow--left {
    left: 0;
}

.carousel-container.single>button.react-multiple-carousel__arrow--right {
    right: 0;
}

.carousel-container.single>button.react-multiple-carousel__arrow {
    border-radius: 0;
    background: none;
}

.carousel-container.single.react-multi-carousel-list {
    position: relative;
    padding-bottom: 40px;
}

.carousal-padding-single {
    padding: 0px 50px;
    position: relative;
}

.single>.react-multi-carousel-dot-list.custom-dot-list-style {
    direction: ltr;
    justify-content: flex-start;
}

.single-custom-dot {
    position: relative;
    width: 14px;
    height: 6px;
    margin-right: 5px;
    border-radius: 4px;
    display: inline-block;
    border: 1px solid #cccccc;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.single-custom-dot-active {
    width: 24px;
    border-color: #d95247;
    background-color: #d95247;
}