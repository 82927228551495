.construction{
  min-height: 500px;
  min-width: 500px;
  
  background-image: linear-gradient(to bottom right ,#d9b7ed, #8cedc3,#d9b7ed );
  
}
i{
  font-size: 50px;
  color:#A1513B;
}