.buttonSection button {
  font-size: 24px;
  border: 1px solid #e44342;
  border-radius: 25px;
  padding: 13px 25px;
  margin-top: 10px;
  margin-bottom: 40px;
  width: 100%;
  background-color: #fff;
  color: #e44342;
  cursor: pointer;
}

.buttonSection button.active {
  background-color: #e44342;
  color: #fff;
  cursor: default;
}

.detailsButton {
  width: 80%;
}


.more_link a:hover {

  /* border-radius: 5px; */
  background-color: transparent;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.lastButton {
  margin-bottom: 60px;
}

/* .detailSection {} */

.detail-container {
  padding-top: 20px;
  padding-bottom: 20px;
  background-repeat: no-repeat;
  background-position: 200px 100%;
  /* background-image: radial-gradient(circle at 100% 50%, rgb(241, 245, 250) 70%, transparent 70%); */
}

.detail-title {
  color: #e44342;
  font-size: 42px;
  text-transform: capitalize;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.detail-description ul {
  color: black;
  font-size: 18px;
  /* line-height: 50px; */
  padding-top: 5px;
  margin-left: 25px;

}

.detail-description ul li {
  list-style-type: disc inside;
  color: #e44342;
  margin-bottom: 30px;
  text-align: justify;
  font-size: 18px;
}

.detail-description ul li span {

  color: #000000;
}

.banner-link {
  color: #fff;
  padding: 0;
  margin: 0;
}

.banner-link:hover {
  color: #a1513b;
  padding: 0;
  margin: 0;
}

.responsiveProgramDetails button {
  font-size: 24px;
  border: 1px solid #e44342;
  border-radius: 25px;
  padding: 5px 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  background-color: #e44342;
  color: #fff;
  cursor: pointer;
}

.responsiveProgramDetails button.collapsed {
  background-color: #fff;
  color: #e44342;
  cursor: default;
}

.responsiveDetailContainer {
  margin: 20px 20px;
}

.responsiveDetailContainer ul li {
  margin-bottom: 20px;
}

.report-heading {
  font-size: 28px;
  color: #000000;
  /* margin-bottom: 30px; */

}

.report-description {
  color: black;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 20px;

}

.report-border {
  /* border: 1px solid #e44342; */
  border-radius: 5px;
  padding: 30px 30px 40px 30px;
  background-color: #fff;

  margin-top: 30px;
  margin-bottom: 30px;
}

.programs-hero-image {
  max-height: 400px;
  width: auto;
}

.report-status-card {
  border: 1px solid #e44342;
  padding: 5px 15px;
  color: #fff;
  background-color: #e44342;
}

tr {
  text-align: center;

  color: #000;
  /* border: 1px solid black; */
}

td {
  text-align: center;
  font-size: 15px;
  color: #000;
  /* border: 1px solid black; */
}

th {
  text-align: center;
  font-size: 20px;
  color: #000;
  border: 1px solid black;
  background-color: #e2aeae;
}

.greyBorder {
  border-radius: 5px;
  padding: 30px 30px 40px 30px;
  background-color: #EFEFEF;
  background-image: linear-gradient(rgb(241, 245, 250), #EFEFEF, rgb(241, 245, 250));

  margin-top: 30px;
  margin-bottom: 30px;
}

/* -------------mobile----------------------- */
@media only screen and (max-width: 600px) {
  .report-description {
    font-size: 18px !important;
  }

  .detail-description ul {
    font-size: 18px !important;
  }

  .report-border {
    margin-top: 0px;
    padding: 10px 10px 10px 10px;
    font-size: 18px;
  }
}


.formbtn {
  padding: 3px 7px 3px !important;
}