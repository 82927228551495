.team_image{
    border-radius:10px ;
    width: 100%;
    height: 300px;
    object-fit: cover;
}
.team_name{
    font-size: 24px;
    color: #000000;
}
.team_designation{
    font-size: 15px;
    margin-bottom: 16px;
}