.news-block {
    position: relative;
    /* margin-bottom: 40px; */
    direction: ltr;
    text-align: left;
    /* margin-top: 30px; */
    
}

.news-block .inner-box {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #dcdcdc;
    background-color: #fff;
}

.news-block .inner-box .image {
    position: relative;
    overflow: hidden;
}

.news-block .inner-box .image:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgb(255 169 60 / 28%);
    content: "";
    -webkit-transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
}

.news-block .inner-box:hover .image:before {
    -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
}

.news-block .inner-box .image img {
    position: relative;
    width: 100%;
    display: block;
}

.news-block .inner-box .lower-content {
    position: relative;
    padding: 30px 28px;
}

.news-block .inner-box .lower-content .time {
    position: relative;
    color: #fa807f;
    font-size: 13px;
    line-height: 1.3em;
}

.news-block .inner-box .lower-content h3 {
    position: relative;
    color: #444444;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2em;
    font-weight: 600;
    margin-top: 14px;

}

.news-block .inner-box .lower-content h3 a {
    position: relative;
    color: #444444;
    transition: all 0.3s ease;
    text-decoration: none;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.news-block .inner-box .lower-content h3 a:hover {
    color: #ffa93c;
}

.news-block .inner-box .lower-content .text {
    position: relative;
    text-align: left;
    color: #7f8497;
    font-size: 16px;
    line-height: 1.5em;
    margin-top: 12px;
    display: block;
    /* or inline-block */
    text-overflow: ellipsis;
    word-wrap: break-all;
    overflow: hidden;
    max-height: 7.4em;

}

.news-block .inner-box .lower-content .author-box {
    position: relative;
    margin-top: 18px;
}

.news-block .inner-box .lower-content .author-box .author-inner {
    position: relative;
    padding-left: 70px;
    padding-top: 8px;
}

.news-block .inner-box .lower-content .author-box .author-inner .image {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 57px;
    height: 57px;
    overflow: hidden;
    border-radius: 50%;
}

.news-block .inner-box .lower-content .author-box .admin {
    position: relative;
    color: #a6a6a6;
    font-size: 13px;
    line-height: 1.3em;
}

.news-block .inner-box .lower-content .author-box .author-name {
    position: relative;
    color: #222222;
    font-size: 17px;
    margin-top: 4px;
}

.news-block .inner-box .lower-content .likes {
    position: relative;
    color: #cfcfcf;
    font-size: 34px;
    line-height: 1em;
    margin-top: 32px;
    display: inline-block;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.news-block .inner-box .lower-content .likes .total {
    position: absolute;
    right: -4px;
    top: -4px;
    width: 15px;
    height: 15px;
    color: #ffffff;
    font-size: 9px;
    text-align: center;
    line-height: 15px;
    border-radius: 50px;
    background-color: #fa807f;
}

.news-block .inner-box .lower-content .likes:hover {
    color: #fa807f;
}

.news_card_image {
    float: left;
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.read_more_news {
    color: #fa807f;
}