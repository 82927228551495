.scheme_card{
    border: 1px solid #b2b2b2;
    background-color: #ffff;
    border-radius: 10px;
    /* height: 100%; */
    padding: 30px;
    margin-bottom: 30px;
    
}
.scheme_card h5{
    color: #000;
    font-weight: bold;
}
.clamp-text {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Change this number to set max lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
@media screen and (max-width: 600px){

    .scheme_image {
        padding-left: 0px!important;
        padding-right: 0px!important;
    }
}