.dedicated-news-heading{
    color: #000;
    font-size: 35px;
    margin-top: 10px;
}
.description_heading_news{
    color: #000;
    font-size: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.gallery_heading_news{
    color: #000;
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 30px;
}