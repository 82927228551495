.edc-subtitle{
  font-size: 22px;
  color: #a1513b;
  font-family: poppins;
}
.event-page-details{
  padding-right: 55px;
}
/* ---------------------mobile------------- */
@media only screen and (max-width: 600px){
  .event-page-details{
    padding-right: 0px;
  }
} 