.faq_banner{
    min-height: 80px;
    
    align-items: center;
}
.faq_banner a{
    padding:8px 14px;
    color: #000000;
    font-size: 13px;
    margin-right: 0px;
    letter-spacing: 2px;
    
    
}
.faq_banner a:hover{
    padding:8px 14px;    
    color: #000000;
    font-size: 13px;
    border-radius:5px;
    background-color: #ddd;
    color:#ff5c35;    
}
.faq_banner .current{
    color:#ff5c35;
}
.banner_title{
    color: #343a40;
    font-size: 24px;
}
/* -------------------mobile screen------------- */
@media only screen and (max-width: 600px){
    .faq_banner a{
        letter-spacing: 0px;
        padding: 8px 10px;
    }

    .faq_banner a:hover{
        padding: 8px 10px;
    }
    .faq_banner h3{
        font-size: 22px;
    }
    .faq_banner{
        align-items: center;
        display: flex;
        justify-content: center;
    }

}