.activity-section-text-container {
    position: relative;
    padding-top: 100px;
}

.activity-section-image-container {
    position: relative;
    text-align: center;
}

.activity-section-image-container::before {
    position: absolute;
    content: "";
    right: 0px;
    top: -60px;
    width: 566px;
    height: 652px;
    z-index: -1;
    display: inline-block;
}

.activity-section-image-container .play-box {
    position: absolute;
    right: 100px;
    bottom: 130px;
    min-width: 195px;
    text-align: left;
    padding: 18px 30px;
    background-color: #ffa93c;
    border-radius: 0px 0px 0px 30px;
    box-shadow: -5px 5px 15px rgba(0, 0, 0, 0.15);
}

.activity-section-image-container .play-box .box-inner {
    position: relative;
    color: #363636;
    font-size: 15px;
    line-height: 1.4em;
    padding-left: 60px;
    font-weight: 700;
    text-transform: uppercase;
}

.activity-section-image-container .play-box .box-inner .play-btn {
    position: absolute;
    left: 0px;
    top: 0px;
    color: #000000;
    font-size: 42px;
    line-height: 1em;
    font-weight: 600;
}

.activity-section-text-container .title {
    position: relative;
    color: #444444;
    font-size: 35px;
    line-height: 1.4em;
    padding-left: 15px;
    padding-right: 15px;
}

.activity-section-text-container .button {
    padding-left: 15px;
    padding-right: 15px;
}

.activity-section-text-container .text {
    position: relative;
    color: #7f8497;
    font-size: 16px;
    padding-left: 15px;
    padding-right: 15px;
    line-height: 1.8em;
    margin-top: 25px;
    margin-bottom: 35px;
    text-align: justify;
}

@media only screen and (max-width: 576px) {
    .activity-section-text-container .title {
        font-size: 30px;
    }
    .activity-section-text-container .button {}
    .activity-section-text-container .text {}
}