.dedicated-news-heading {
  color: #000;
  font-size: 35px;
  margin-top: 10px;
}

.event-detail-para {
  color: #000;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: justify;
  padding: 0px 55px 0px 0px;
}

.gallery_heading_news {
  color: #000;
  font-size: 30px;
  margin-top: 10px;
  margin-bottom: 30px;
}

/* .event-detail-heading{
  color: #000;
  font-size: 18px;
  font-family: poppins;
  text-align: justify;
} */

.event-row {
  padding-top: 0px;
  padding-bottom: 100px;
}

.event-detail-heading {
  /* color: #000; */
  font-size: 30px;
  margin-top: 10px;
  color: #ff7f49;
}

.sub-titles-event {
  color: #a1513b;
}

.admin-editor ul {
  padding-left: 25px;
}

/* ---------------mobile------------------ */

@media only screen and (max-width: 600px) {
  .event-detail-heading {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center
  }

  .event-row {
    padding-top: 5px;
    padding-bottom: 100px;
  }

  .event-detail-para {
    padding: 0px;
    font-size: 15px;
    padding: 0px !important;
  }

  .description_heading_news {
    text-align: justify;
  }

  .Schemes_heading_description {
    font-size: 16px;
  }
}