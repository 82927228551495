.faq_questions{
    color: #e44342;
    font-size: 24px;
    margin-bottom: 15px;
}
.faq_answers{
    color: #000000;
    font-size: 16px;
    text-align: justify;
    font-weight: 400;
    line-height: 1.8rem;
}
 li{
    list-style-type: disc;
 }