.schemes_heading{
    font-size: 28px;
    color: #FFA93C;
}
.schemes_sub_heading{
    font-size: 20px;
    color: #FFA93C;
}
.Schemes_heading_description{
    font-size: 18px;
    color: #000000;
    text-align: justify;
}
.Schemes_heading_description li{
    font-size: 18px;
    color: #000000;
    text-align: justify;
    margin-top: 15px;
}
.schemes_download{
    color: #E24948;
    font-size: 15px;
    font-weight: bold;
    border-radius: 5px;
}
.schemes_download :hover{
    color: #E24948;
    font-size: 15px;
    font-weight: bold;
    background-color: #d9d9d9;
    border-radius: 5px;
}
.download_description{
    font-size:15px ;
    color: #777777;
    font-family: sans-serif;
}
.arrow_icon{
    font-size: 40px;
}
.sub_list li{
    margin-left: 40px;
    list-style-type:circle;
}