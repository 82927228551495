.carousel-container.multi>button.react-multiple-carousel__arrow--left {
    left: 0;
}

.carousel-container.multi>button.react-multiple-carousel__arrow--right {
    right: 0;
}

.carousel-container.multi>button.react-multiple-carousel__arrow {
    border-radius: 0;
    background: none;
}

.carousel-container.multi.react-multi-carousel-list {
    position: unset;
}

.carousal-padding-multi {
    padding: 0px 50px;
    position: relative;
}

.carousel-container.multi>button.react-multiple-carousel__arrow.flaticon-back-5:before,
.carousel-container.multi>button.react-multiple-carousel__arrow.flaticon-next-7:before {
    color: #d0d0d0;
    font-size: 50px;
}

.carousel-container.multi>button.react-multiple-carousel__arrow.flaticon-back-5:hover::before,
.carousel-container.multi>button.react-multiple-carousel__arrow.flaticon-next-7:hover::before {
    color: #ffa93c;
    font-size: 50px;
}