
.company-heading h3{
  display: flex;
  justify-content: center;
  color: #ffa93c;
  font-weight: normal;
  font-size: 28px;
  margin-top: 40px;
  margin-bottom: 104px;
}
.company-heading h4{
 color:#444444;
 font-size: 20px;
 font-weight: 400;
}
.company-heading p{
  color:#444444 ;
  font-size: 18px;
  margin-top: 10px;
  text-align: justify;
  line-height: 1.8rem;
  font-family: inherit;
}
.submit-btn{
  margin-top: 80px;
  margin-bottom: 80px;
}
.company-details p{
  color:#9a563e; 
}