.news_author_image{
    border-radius: 50%;
    width: 35px;
}
.news_author_container{
    background-color: #00000008;
    border-top: 1px solid #777777;
}
.news_author_name{
    color:#000;
    font-weight: bold;
    font-size: 15px;
}
.news_author_date{
    font-size: 12px;
    color: #000000;
}
.news_content_title{
    color:#000000;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.6em;
    padding-bottom: 7px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    
}
.news_content_description{
    color: #000000;
    font-size: 15px;
    text-align: justify;
}
.news_content_container{
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}
.news_tag_container{
    padding:20px;
    
}
.news_tag_container a{
    color: #e24948;
    font-size: 12px;
    
}
.news_tag{
    color: #ff7f49;
    font-size: 20px;

}