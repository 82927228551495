.directory_search_box{
    border: 1px solid #fc2436;
    padding: 5px;
    border-radius: 5px;
    min-width: 240px;
}

.directory_search_button{
    background-color: #fc2436;
    border: 1px solid #fc2436;
    color: #ffffff;
    border-radius: 5px;
    padding: 5px 10px;
    margin-left: 8px;
}
.directory_acordion button{
    border: 0;
    background: none;
    width: 100%;
}
.directory_acordion{
    border: 0;
    width: 100%;
    padding: 10px;
    border-radius: 7px;
    text-align: left;
    font-size: 18px;
    display: flex;
}
.directory_content{
    text-align: justify;
    margin: 20px;
    font-size: 18px;
    color: #000;
}
.problems-round{
    border: 1px solid #000;
    border-radius: 50%;
    padding: 2px;
}
.problem-image{
    max-width: 75%;
    padding-left: 2px;
    padding-right: 2px;
}
.problem-button{
    background-color: #fc2436;
    border: 2px solid #fc2436;
}
.problem-button:hover{
    background-color:#ffffff;
    color: #fc2436;
    border: 2px solid #fc2436;
}