.contactus-details {
  margin-top: 70px;
  margin-bottom: 20px;
}
.contactus-heading h2{
  color: #444444;
  font-size: 30px;
  font-weight: 400;
}
.contact-icons{
  height: 60px;
  width: 60px;
  color: #a1513b;
}
.contact-icons-phn{
  height: 40px;
  width: 60px;
  margin-top: 10px;
  color: #a1513b;
}
.contact-icons-email{
  height: 50px;
  width: 55px;
  color: #a1513b;
}
.icon-section{
  margin-bottom: 45px;
}
.icon-section  h3 {
  color: #444444;
  font-size: 20px;
}
.icon-section  p {
  color: #444444;
  font-size: 18px;
}
.details-section{
  margin-top: 40px;
}
.icon-details{
  padding: 20px;
}
.contact-field-name{
  display: flex;
}
.field-address{
  padding: 10px;
  width: 10
  
  0%;
  margin-right: 5px;
  margin-bottom: 55px;
}
.contact-form{
  padding: 30px 20px;
}
.input-details{
  margin-bottom: 55px;
  width: 99.3%;
  padding: 10px;
}
.input-details-two{
  margin-bottom: 45px;
  width: 99.3%;
  padding: 7px;
  height: 130px;
}


/* .....................mobile................ */
@media only screen and (max-width: 600px){
  .contactus-details{
    margin-top: 20px;
  }
  .contactus-heading h2{
    color: #444444;
    font-size: 25px;
    font-weight: 400;
    display: flex;
    justify-content: center;
  }
  .contact-icons{
    height: 30px;
    width: 30px;
    color: #a1513b;
  }
  .contact-icons-phn{
    height: 20px;
    width: 40px;
    margin-top: 10px;
    color: #a1513b;
  }
  .contact-icons-email{
    height: 25px;
    width: 27px;
    color: #a1513b;
  }
  .icon-section{
    margin-bottom: 15px;
  }
  .details-section{
    margin-top: 10px;
  }
  .icon-section  h3 {
    color: #444444;
    font-size: 18px;
  }
}