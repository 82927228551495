.card_container {
  border: 1px solid #000;
  border-radius: 5px;
}

.company_card_name {
  font-size: 25px;
  color: #000;
  font-weight: bolder;
}

.company_card_description {
  font-size: 18px;
  color: #000;

}

.internship_popup {
  /* max-width: 1000px !important; */
  margin-top: 150px;
}

.internship_popup .modal-dialog {
  max-width: 900px !important;


}

.companyName {
  font-size: 24px;
  /* font-weight: bold; */
  color: #000;
}

.intern-content {
  font-size: 16px;
  color: #000
}