.feature-container {
  border: 1px solid black;
  border-radius: 5px;
}

.feature-card-inner-box {
  width: 100%;

}

.feature-card-inner-box:before {
  position: absolute;
  color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  content: "";
  left: 0%;
  top: 0%;
  width: 0;
  height: 100%;
  background-color: rgb(255 169 60 / 10%);
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 100ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}


.feature-card-icon {
  height: 100px;
  width: 100px;
}

.feature-card-title {
  font-size: 20px;
  /* z-index: 1; */
  font-weight: 600;
  text-transform: capitalize;
  margin-top: 5px;
  line-height: 40px;
  font-family: sans-serif;
}

.feature-card-subtitle {
  font-size: 15px;
  /* z-index: 1; */
  font-weight: 400;
  text-transform: capitalize;
  margin-top: 5px;
  line-height: 20px;
  font-family: sans-serif;
}

.service-button {
  color: #fff;
  background-color: #d95247;
  border: 0;
  padding: 5px 10px;
  ;
  border-radius: 5px;
}