.schemes_heading {
    font-size: 25px;
    color: #FFA93C;
    font-weight: bold;
    
}



.Schemes_heading_description {
    font-size: 18px;
    color: #000000;
    text-align: justify;
    /* padding: 0px 55px 0px 0px; */
}

.Schemes_heading_description li {
    font-size: 18px;
    color: #000000;
    text-align: left;
    margin-top: 15px;
}

.schemes_download {
    color: #E24948;
    font-size: 15px;
    font-weight: bold;
    border-radius: 5px;
}

.schemes_download :hover {
    color: #E24948;
    font-size: 15px;
    font-weight: bold;
    background-color: #d9d9d9;
    border-radius: 5px;
}

.download_description {
    font-size: 15px;
    color: #777777;
    font-family: sans-serif;
}

.arrow_icon {
    font-size: 40px;
}

.sub_list li {
    margin-left: 40px;
    list-style-type: circle;
}
/* ---------------------mobile--------- */
@media screen and (max-width: 600px) {
    .schemes_heading{
        font-size: 21px;
        line-height: 35px;
        font-weight: 300;
        text-align: center;
        color:#e74c3c;
        font-weight: bold;
    }
    .Schemes_heading_description p{
        font-size: 16px !important;
    }
    .head-container{
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
    .schemes-fee{
        padding: 15px;
        font-size: 18px;
    }
    .fess-discription{
        font-size: 15px;
    }
    .Schemes_heading_description{
        padding: 0px 0px;
    }
    }