.more_link {
    margin: 10px 2px;
}

.offerings {
    background-color: #f3e7e7;
    ;
}

.criteria {
    font-size: 21px;
    line-height: 60px;
    color: #000;

}

.criteria ol li {
    list-style: decimal;
    margin-left: 40px;
}

.structure_points {
    font-size: 21px;
    line-height: 60px;
    color: #000;

}

.structure_points ol li {
    list-style: none;
}

.structure_points ul li {
    list-style: none;
}

.program-banner-image {
    width: 150px;
    /* height: 400px; */

}

/* .prog-banner:hover{
    padding: 16px 8px;
    border-radius:5px;
    border:0;
       
} */
.prog-banner {
    /* padding: 16px 0px;  */
    color: #913c0e;
    font-size: 21px;
    font-weight: bold;
}
.prog-banner-2 {
    /* padding: 16px 0px;  */
    /* color: #ffa93c; */
    color: #fff;
    font-size: 21px;
    font-weight: semi-bold;
}

.download-btn {
    padding: 16px 8px;
    color: #e24948;

}

.download-btn.hover {
    padding: 16px 8px;
    border-radius: 5px;
    border: 0;
    background-color: #ddd;
}

.inovation_sub_headings {
    font-size: 22px;
    color: #000;
    padding-left: 10px;

}