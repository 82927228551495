@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");

* {
  box-sizing: border-box;
}

.login-container span {
  font-size: 12px;
}

.login-container a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

.login-container button {
  border-radius: 20px;
  border: 1px solid #ff4b2b;
  background-color: #ff4b2b;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  /* text-transform: uppercase; */
  transition: transform 80ms ease-in;
}

.login-container button:active {
  transform: scale(0.95);
}

.login-container button:focus {
  outline: none;
}

.login-container button.ghost {
  background-color: #ff4b2b;
  border-color: #ff4b2b;
}

.login-container form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

.login-container input,
.login-container select {
  background-color: #eee;
  border: none;
  padding: 6px 15px;
  margin: 8px 0;
  width: 100%;
}

.login-container {
  background-color: #fff;
  border-radius: 10px;
  /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); */
  position: relative;
  overflow: hidden;
  /* width: 768px; */
  max-width: 100%;
  min-height: 720px;
}

.form-login-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-login-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.login-container.right-panel-active .sign-in-login-container {
  transform: translateX(100%);
}

.sign-up-login-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.login-container.right-panel-active .sign-up-login-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

.directory_acordion>button.accordion-button {
  color: white;
  font-weight: 700;
}

@keyframes show {

  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-login-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.login-container.right-panel-active .overlay-login-container {
  transform: translateX(-100%);
}

.login-container .overlay {
  /* background-image: url("../../assets/images/resourses/signuplogin.png"); */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  /* background-position: 0 0; */
  color: #000;
  position: relative;
  left: -90%;
  height: 50%;
  width: 180%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.login-container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.login-container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.login-container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.forgot_button {
  background-color: #fff;
  color: #ff4b2b;
  border: 0;
}

.forgot_password_popup {
  margin-top: 220px;
}

.forgot_title {
  color: #ff4b2b;
}

.forgot_button_secondary {
  background-color: #ffc107;
  border: 1px solid #fff;
}

.forgot_button_secondary:hover {
  border: 1px solid #ff4b2b;
  background-color: #fff;
  color: #ff4b2b;
}

.forgot_button_primary {
  background-color: #ff4b2b;
  border: 1px solid #fff;
}

.forgot_button_primary:hover {
  background-color: #fff;
  border: 1px solid #ffc107;
  color: #ffc107;
}