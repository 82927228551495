.prog_detail_image{
    max-width: 100%;
}
.program_details{
    border: 1px solid #d5d5d5;
    color: #a1513b;
}
.program_details_heading{
    color: #000000;
    text-align: center;
    font-size: 28px;
    margin-bottom: 43px;
}
.program_details_inner{
    padding: 25px 25px;

}
.program_date_border{
    border-bottom: 1px solid #000000;
}
.program_details_date{
    font-size: 20px;
    font-weight: 330;
    text-align: left;
    padding-bottom: 45px;
    letter-spacing: 1px;
}
.program_details_location{
    font-size: 20px;
    text-align: left;
    padding-top: 45px;
    font-weight: 330;
        letter-spacing: 1px;
}
.program_details_location span{
    text-align: justify;
}
.academia_heading{
    color: #ffa93c
}
.academy_program_card{
    color: #ffa93c;
    font-size: 24px;
    font-weight: 550;
}
.program_date_inner{
    padding-left: 20px;
    padding-right: 20px;
}

