.feature-card-inner-box {
    position: relative;
    text-align: center;
    border-radius: 8px;
    overflow: hidden;
    height: 222px;
    padding: 30px 20px 45px;
    border: 1px solid #dedede;
    background: white;
}

.feature-card-inner-box:before {
    position: absolute;
    color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    content: "";
    left: 0%;
    top: 0%;
    width: 0px;
    height: 100%;
    background-color: rgb(255 169 60 / 50%);
    -webkit-transition: all 1000ms ease;
    -moz-transition: all 100ms ease;
    -ms-transition: all 1000ms ease;
    -o-transition: all 1000ms ease;
    transition: all 1000ms ease;
}

.feature-card-inner-box>div.hover-details {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
    background-color: red;
}

.feature-card-inner-box>div.hover-details:hover {
    display: block;
    color: #fff;
}

.feature-card-inner-box:hover::before {
    width: 100%;
    color: transparent;
}



.feature-card-inner-box:hover {
    border-color: #e9f0ff;
}

.feature-card-icon {
    height: 100px;
    width: 100px;
}

.feature-card-title {
    font-size: 20px;
    /* z-index: 1; */
    font-weight: 600;
    text-transform: capitalize;
    margin-top: 5px;
    line-height: 40px;
    font-family: sans-serif;
}

.hover-details {
    color: transparent;
    border: 5px solid red;
}

.hover-details:hover {
    color: red;

}