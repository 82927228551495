.reg-card-bg {
    background: whitesmoke;
    background-color: yellow;
    border-radius: 15px;

}
.regcard-full{
    border-radius: 10px;
}
.regcard-img{
    height: 40px;
    object-fit: cover;
    /* border-left: 1px solid black; */
}

.overlay-color {
    /* background: linear-gradient(180deg, rgba(0, 0, 0, 0) 17.61%, rgba(0, 0, 0, 0.24) 40.35%, rgba(0, 0, 0, 0.606331) 69.1%, #000000 100%); */
}

.reg-card-datas {
    display: flex;
    align-items: center;
    font-family: 'Poppins';
    font-style: normal;
}

.reg-card-datas.title {
    font-weight: 500;
    font-size: 15px;
    line-height: 40px;
    text-align: center;
    /* font-family: sans-serif; */
    /* color: #fff; */
    text-transform: uppercase;
    letter-spacing: 1px;
}
.reg-card-datas.title :hover {
    font-weight: 500;
    font-size: 15px;
    line-height: 40px;
    text-align: center;
    /* font-family: sans-serif; */
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.reg-card-datas.desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}
.card-title-head{
    background-color: #fff;
    box-shadow: 5px 5px 10px lightblue;
    border-radius: 2px;
    color:#000 !important;
    border: 1px solid rgb(221, 220, 220);
    text-align: left;
    
}
.card-title-head:hover{
    background-color: #d95247;
    box-shadow: 10px 10px 5px lightblue;
    border-radius: 2px;
    color:#fff !important;
    
}
/* .card-title-black{
    color: #000 !important;
    
} */
