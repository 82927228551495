.team-img{
    height: 300px;
    border-radius: 10px;
}
.team-name{
    font-size: 28px;
    color:#000000;
    margin-bottom: 6px;

}
.team-designation{
    font-size: 15px;
    margin-bottom: 16px;
    color: #000000;
}
.team-description{
    font-size: 18px;
    margin-bottom: 16px;
    color: #000000;
    text-align: justify;
    
}
.team-content{
    border-bottom: 1px solid black;
}