.inovation_text {
    text-align: justify;
}

.program_line {
    border-top: 1px solid black;
}

.inovation_color {
    color: #a1513b;
}

.programs_title {
    font-weight: bold;
    color: #000000;
    font-size: 18px;
}

.programs_title a:hover {
    color: #ffa93c;
}

.programs_title a {
    color: #000000;
}

.programs_participant {
    font-size: 16px;
    color: #000000;
}

.program_card_contents_container {
    padding-left: 3px;
}

.progrram-image {
    height: 400px;
    width: 450px;
    border-radius: 5px;
    object-fit: fill;
}

.card-border {
    border: solid 1px #d4d4d4;
    border-radius: 5px;
    margin-top: 45px;
    margin-bottom: 45px;
}