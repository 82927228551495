.success_icon{
    height: 100px;
    width: 100px;
    color: #ffa93c;
    ;
}
.success_card{
    border: 1px solid #646769 ;
    border-radius: 10px;
    width: 750px;
    
}
.success_title{
    color: #000000;
    font-weight:800;
}
.success_desc{
    color: #424242;
}
.success{
    padding-top: 50px;
    padding-bottom: 50px;
}

.success_title h4{
    color: #000000;
    font-weight:600;
    font-size: 28px;
    text-align: center;
}
.success_desc h6{
    color: #424242;
    text-align: center;
}
