.formstyle {

    display: flex;
    flex-direction: column;
    /* padding-left: 15px; */
    padding-right: 15px;
    color: #000000;
    font-size: 17px;


}

.inputbox {
    border: 1px solid #ced4da;
    line-height: 1.5rem;
    font-size: 1rem;
    border-radius: .25rem;
    padding: .375rem .375rem
}

.form {
    max-width: 900px;

}

.registerbtn {
    letter-spacing: 2px;
    padding: 8px;
    font-weight: 600;
    font-size: 15px;


}

.error {
    font-size: 13px;
    color: red;
}

/* .......................mobile..................... */
@media only screen and (max-width: 600px){
    .mandatory-field p span{
        display: none;
    }
    .registration-banner{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .formtitle{
        text-align: center;
    }
}