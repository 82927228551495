.testimonial-section {
    position: relative;
    padding-top: 75px;
    padding-bottom: 0px;
}

.testimonial-section.style-two {
    padding: 120px 0px 100px;
}

.testimonial-section .carousel-column {
    position: relative;
}

.testimonial-section .carousel-column .owl-nav {
    display: none;
}

.testimonial-section .carousel-column .owl-dots {
    position: relative;
    margin-top: 30px;
}

.testimonial-section .carousel-column .owl-dots .owl-dot {
    position: relative;
    width: 14px;
    height: 6px;
    margin-right: 5px;
    border-radius: 4px;
    display: inline-block;
    border: 1px solid #cccccc;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.testimonial-section .carousel-column .owl-dots .owl-dot.active,
.testimonial-section .carousel-column .owl-dots .owl-dot:hover {
    width: 24px;
    border-color: #ffa93c;
    background-color: #ffa93c;
}

.testimonial-section .carousel-column .inner-column {
    position: relative;
    padding-top: 40px;
    padding-right: 60px;
}

.testimonial-section .carousel-column .inner-column h2 {
    position: relative;
    color: #444444;
    font-size: 20px;
    line-height: 1.3em;
}

.testimonial-section .carousel-column .inner-column .designation {
    position: relative;
    color: #777777;
    font-size: 15px;
    line-height: 1.3em;
    margin-top: 12px;
}

.testimonial-section .carousel-column .inner-column .quote-icon {
    position: relative;
    color: #ffa93c;
    font-size: 36px;
    margin: 20px 0px 25px;
}

.testimonial-section .carousel-column .inner-column .text {
    position: relative;
    color: #7f8497;
    font-size: 16px;
    line-height: 1.8em;
}

.testimonial-section .carousel-column .inner-column .big-letter {
    position: absolute;
    right: 85px;
    top: 50px;
    color: #f7f9ff;
    line-height: 1em;
    font-size: 400px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
}

.testimonial-section .image-column {
    position: relative;
}

.testimonial-section .image-column .inner-column {
    position: relative;
    min-height: 580px;
}

.testimonial-section .image-column .inner-column #particles-js {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
}

.testimonial-section .image-column .inner-column .author-one {
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    overflow: hidden;
    width: 154px;
    height: 154px;
    margin-left: -77px;
    margin-top: -77px;
    transition: all 1200ms ease;
    -moz-transition: all 1200ms ease;
    -webkit-transition: all 1200ms ease;
    -ms-transition: all 1200ms ease;
    -o-transition: all 1200ms ease;
}

.testimonial-section .image-column .inner-column .author-one.now-in-view {
    left: 5%;
    top: 42%;
}


/*.testimonial-section .image-column .inner-column .author-one{
	position:absolute;
	left:0%;
	top:42%;
	border-radius:50%;
	overflow:hidden;
	width:154px;
	height:154px;
} */

.testimonial-section .image-column .inner-column .author-two {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 94px;
    height: 94px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: -47px;
    margin-top: -47px;
    transition: all 1200ms ease;
    -moz-transition: all 1200ms ease;
    -webkit-transition: all 1200ms ease;
    -ms-transition: all 1200ms ease;
    -o-transition: all 1200ms ease;
}

.testimonial-section .image-column .inner-column .author-two.now-in-view {
    left: 20%;
    top: 15%;
}

.testimonial-section .image-column .inner-column .author-three {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: -40px;
    margin-top: -40px;
    transition: all 1200ms ease;
    -moz-transition: all 1200ms ease;
    -webkit-transition: all 1200ms ease;
    -ms-transition: all 1200ms ease;
    -o-transition: all 1200ms ease;
}

.testimonial-section .image-column .inner-column .author-three.now-in-view {
    left: 45%;
    top: 10%;
}

.testimonial-section .image-column .inner-column .author-four {
    position: absolute;
    right: 50%;
    top: 50%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: -25px;
    margin-top: -25px;
    transition: all 1200ms ease;
    -moz-transition: all 1200ms ease;
    -webkit-transition: all 1200ms ease;
    -ms-transition: all 1200ms ease;
    -o-transition: all 1200ms ease;
}

.testimonial-section .image-column .inner-column .author-four.now-in-view {
    right: 30%;
    top: 0%;
}

.testimonial-section .image-column .inner-column .author-five {
    position: absolute;
    right: 50%;
    top: 50%;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: -36px;
    margin-top: -36px;
    transition: all 1200ms ease;
    -moz-transition: all 1200ms ease;
    -webkit-transition: all 1200ms ease;
    -ms-transition: all 1200ms ease;
    -o-transition: all 1200ms ease;
}

.testimonial-section .image-column .inner-column .author-five.now-in-view {
    right: 15%;
    top: 14%;
}

.testimonial-section .image-column .inner-column .author-six {
    position: absolute;
    right: 50%;
    top: 50%;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: -36px;
    margin-top: -36px;
    transition: all 1200ms ease;
    -moz-transition: all 1200ms ease;
    -webkit-transition: all 1200ms ease;
    -ms-transition: all 1200ms ease;
    -o-transition: all 1200ms ease;
}

.testimonial-section .image-column .inner-column .author-six.now-in-view {
    right: 30%;
    top: 30%;
}

.testimonial-section .image-column .inner-column .author-seven {
    position: absolute;
    right: 50%;
    top: 50%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: -22px;
    margin-top: -22px;
    transition: all 1200ms ease;
    -moz-transition: all 1200ms ease;
    -webkit-transition: all 1200ms ease;
    -ms-transition: all 1200ms ease;
    -o-transition: all 1200ms ease;
}

.testimonial-section .image-column .inner-column .author-seven.now-in-view {
    right: -10%;
    top: 40%;
}

.testimonial-section .image-column .inner-column .author-eight {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: -50px;
    margin-top: -50px;
    transition: all 1200ms ease;
    -moz-transition: all 1200ms ease;
    -webkit-transition: all 1200ms ease;
    -ms-transition: all 1200ms ease;
    -o-transition: all 1200ms ease;
}

.testimonial-section .image-column .inner-column .author-eight.now-in-view {
    left: 35%;
    top: 48%;
}

.testimonial-section .image-column .inner-column .author-nine {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: -23px;
    margin-left: -23px;
    transition: all 1200ms ease;
    -moz-transition: all 1200ms ease;
    -webkit-transition: all 1200ms ease;
    -ms-transition: all 1200ms ease;
    -o-transition: all 1200ms ease;
}

.testimonial-section .image-column .inner-column .author-nine.now-in-view {
    left: 65%;
    top: 60%;
}

.testimonial-section .image-column .inner-column .author-ten {
    position: absolute;
    right: 50%;
    top: 50%;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: -29px;
    margin-top: -29px;
    transition: all 1200ms ease;
    -moz-transition: all 1200ms ease;
    -webkit-transition: all 1200ms ease;
    -ms-transition: all 1200ms ease;
    -o-transition: all 1200ms ease;
}

.testimonial-section .image-column .inner-column .author-ten.now-in-view {
    right: 0%;
    top: 66%;
}

.testimonial-section .image-column .inner-column .author-eleven {
    position: absolute;
    left: 50%;
    bottom: 50%;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: -29px;
    margin-top: -29px;
    transition: all 1200ms ease;
    -moz-transition: all 1200ms ease;
    -webkit-transition: all 1200ms ease;
    -ms-transition: all 1200ms ease;
    -o-transition: all 1200ms ease;
}

.testimonial-section .image-column .inner-column .author-eleven.now-in-view {
    left: 25%;
    bottom: 10%;
}


/*** 

====================================================================
	Testimonial Section Two
====================================================================

***/

.testimonial-section-two {
    position: relative;
    padding: 80px 0px 80px;
}

.testimonial-section-two .carousel-column {
    position: relative;
    z-index: 1;
}

.testimonial-section-two .carousel-column .owl-nav {
    display: none;
}

.testimonial-section-two .carousel-column .owl-dots {
    position: relative;
    margin-top: 30px;
}

.testimonial-section-two .carousel-column .owl-dots .owl-dot {
    position: relative;
    width: 14px;
    height: 6px;
    margin-right: 5px;
    border-radius: 4px;
    display: inline-block;
    border: 1px solid #cccccc;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.testimonial-section-two .carousel-column .owl-dots .owl-dot.active,
.testimonial-section-two .carousel-column .owl-dots .owl-dot:hover {
    width: 24px;
    border-color: #ffa93c;
    background-color: #ffa93c;
}

.testimonial-section-two .carousel-column .inner-column {
    position: relative;
    padding-top: 40px;
    padding-right: 0px;
}

.testimonial-section-two .carousel-column .inner-column h2 {
    position: relative;
    color: #d95247;
    font-size: 32px;
    line-height: 1.3em;
}

.testimonial-section-two .carousel-column .inner-column .designation {
    position: relative;
    color: #777777;
    font-size: 17px;
    line-height: 1.3em;
    margin-top: 12px;
    font-weight: bold;
}

.testimonial-section-two .carousel-column .inner-column .quote-icon {
    position: relative;
    color: #d95247;
    font-size: 36px;
    margin: 20px 0px 25px;
}

.testimonial-section-two .carousel-column .inner-column .text {
    position: relative;
    color: #7f8497;
    font-size: 16px;
    line-height: 1.8em;
    text-align: justify;
}

.testimonial-section-two .carousel-column .inner-column .big-letter {
    position: absolute;
    right: 85px;
    top: 100px;
    color: #f7f9ff;
    line-height: 1em;
    font-size: 400px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
}

.testimonial-section-two .image-column {
    position: relative;
    text-align: center;
}

.testimonial-section-two .image-column .inner-column {
    position: relative;
    min-height: 380px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../../../../assets/images/resourses/map.png) center center no-repeat;
}


/* Circle One */

.testimonial-section-two .image-column .inner-column .circle-one {
    position: absolute;
    /* left: 70px;
    top: 0px; */
    width: 380px;
    height: 380px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px dashed #bdbbbc;
    transition: all 1200ms ease;
    -moz-transition: all 1200ms ease;
    -webkit-transition: all 1200ms ease;
    -ms-transition: all 1200ms ease;
    -o-transition: all 1200ms ease;
}

.testimonial-section-two .image-column .inner-column .circle-two {
    position: absolute;
    /* left: 135px;
    top: 65px; */
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    border-radius: 50%;
    border: 2px dashed #bdbbbc;
    transition: all 1200ms ease;
    -moz-transition: all 1200ms ease;
    -webkit-transition: all 1200ms ease;
    -ms-transition: all 1200ms ease;
    -o-transition: all 1200ms ease;
}


/* Author One */

.testimonial-section-two .image-column .inner-column .author-one {
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    width: 89px;
    height: 89px;
    border-radius: 50%;
    overflow: hidden;
    padding: 5px;
    margin-left: -44.5px;
    margin-top: -44.5px;
    display: flex;
    background-color: #ffffff;
    transition: all 1200ms ease;
    -moz-transition: all 1200ms ease;
    -webkit-transition: all 1200ms ease;
    -ms-transition: all 1200ms ease;
    -o-transition: all 1200ms ease;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
}

.testimonial-section-two .image-column .inner-column .author-one img {
    border-radius: 50%;
}

.testimonial-section-two .image-column .inner-column .author-one.now-in-view {
    left: 26%;
    top: 13%;
}


/* Author Two */

.testimonial-section-two .image-column .inner-column .author-two {
    position: absolute;
    content: "";
    right: 50%;
    top: 50%;
    width: 66px;
    display: flex;
    height: 66px;
    border-radius: 50%;
    overflow: hidden;
    padding: 5px;
    margin-right: -33px;
    margin-top: -33px;
    transition: all 1200ms ease;
    -moz-transition: all 1200ms ease;
    -webkit-transition: all 1200ms ease;
    -ms-transition: all 1200ms ease;
    -o-transition: all 1200ms ease;
    background-color: #ffffff;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
}

.testimonial-section-two .image-column .inner-column .author-two img {
    border-radius: 50%;
}

.testimonial-section-two .image-column .inner-column .author-two.now-in-view {
    right: 18%;
    top: 30%;
}


/* Author Three */

.testimonial-section-two .image-column .inner-column .author-three {
    position: absolute;
    content: "";
    left: 50%;
    bottom: 50%;
    width: 72px;
    display: flex;
    height: 72px;
    border-radius: 50%;
    overflow: hidden;
    padding: 5px;
    margin-left: -36px;
    margin-bottom: -36px;
    transition: all 1200ms ease;
    -moz-transition: all 1200ms ease;
    -webkit-transition: all 1200ms ease;
    -ms-transition: all 1200ms ease;
    -o-transition: all 1200ms ease;
    background-color: #ffffff;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
}

.testimonial-section-two .image-column .inner-column .author-three img {
    border-radius: 50%;
}

.testimonial-section-two .image-column .inner-column .author-three.now-in-view {
    left: 24%;
    bottom: 0%;
    margin: 0px;
}


/* Author Four */

.testimonial-section-two .image-column .inner-column .author-four {
    position: absolute;
    content: "";
    right: 50%;
    bottom: 50%;
    width: 44px;
    display: flex;
    height: 43px;
    border-radius: 50%;
    overflow: hidden;
    padding: 5px;
    margin-right: -22px;
    margin-bottom: -22px;
    transition: all 1200ms ease;
    -moz-transition: all 1200ms ease;
    -webkit-transition: all 1200ms ease;
    -ms-transition: all 1200ms ease;
    -o-transition: all 1200ms ease;
    background-color: #ffffff;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
}

.testimonial-section-two .image-column .inner-column .author-four img {
    border-radius: 50%;
}

.testimonial-section-two .image-column .inner-column .author-four.now-in-view {
    right: 28%;
    bottom: 11%;
}


/* Author Five */

.testimonial-section-two .image-column .inner-column .author-five {
    position: absolute;
    content: "";
    left: 41.5%;
    bottom: 38%;
    width: 230px;
    height: 230px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: 5px;
    margin-left: -68.5px;
    margin-bottom: -68.5px;
    transition: all 1200ms ease;
    -moz-transition: all 1200ms ease;
    -webkit-transition: all 1200ms ease;
    -ms-transition: all 1200ms ease;
    -o-transition: all 1200ms ease;
    background-color: #ffffff;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
}

.testimonial-section-two .image-column .inner-column .author-five img {
    border-radius: 50%;
}


/* .testimonial-section-two .image-column .inner-column .author-five.now-in-view { */


/* left: 34%;
    bottom: 24%;
    margin: 0px; */


/* } */


/* Icon */

.testimonial-section-two .image-column .inner-column .icon {
    position: absolute;
    content: "";
    left: 50%;
    bottom: 50%;
    width: 44px;
    height: 43px;
    border-radius: 50%;
    overflow: hidden;
    padding: 5px;
    color: #ffffff;
    margin-left: -22px;
    margin-bottom: -22px;
    transition: all 1200ms ease;
    -moz-transition: all 1200ms ease;
    -webkit-transition: all 1200ms ease;
    -ms-transition: all 1200ms ease;
    -o-transition: all 1200ms ease;
    background-color: #ffffff;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
}

.testimonial-section-two .image-column .inner-column .icon .fa {
    background-color: #ffa93c;
    color: #ffffff;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
}

.testimonial-section-two .image-column .inner-column .icon.now-in-view {
    left: 11%;
    bottom: 40%;
    margin: 0px;
}

.title {
    color: #000;
}

.text {
    position: relative;
    color: #3e414a;
    font-size: 16px;
    line-height: 1.8em;
    text-align: left;
}

.designation {
    position: relative;
    color: #777777;
    font-size: 17px;
    line-height: 1.3em;
    margin-top: 12px;
    font-weight: bold;
}

.quote-icon {
    position: relative;
    color: #d95247;
    font-size: 36px;
    margin: 20px 0px 25px;
}