.mentor_image {
    height: 180px;
    width:180px;
    border: solid 1px #d5d5d5;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
    border-radius: 50%;
    /* object-fit:; */
    
}
.mentor_card_full{
    max-width: 75%;
    
}
.card_mentor{
    background-color: #ffff;
    padding-bottom: 2rem;
    /* box-shadow: 0px 10px 13px -7px #000000; */
    padding-top: 8px;
    

}
.mentor-picture{
    padding: 25px 25px 10px 25px;

}

.mentor_card {
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    max-height: 100%;
}

.mentor_name {
    /* padding: 15px 0 15px 0; */
    font-size: 18px;
    color: #000;
    border-top: solid 1px #0000;
    text-align: center;
}

.mentor_name .mentor_designation {
    color: #e44342;
    font-size: 15px;
}