.inovation_text p {
    text-align: justify;
    font-size: 18px;
    color: #000;
    line-height: 1.8rem;
    font-family: inherit;
    margin-bottom: 0;

}

.inovation_banner_image {
    width: 150px;
}

.inovation_headings {
    font-size: 42px;
    font-family: inherit;
    color: #e44342;
    font-weight: 400px;
}

.hero-img {
    padding: 0px 50px 0px 0px;
    height: 100%;
}

.inovation_headings_logo {
    width: 40px;
}

.more_link a:hover {
    padding: 16px 2px;
    border-radius: 5px;
    background-color: #ddd;
}

.more_link a {
    padding-left: 2px;
}

.scheme_image {
    padding: auto 10px;

}

.scheme_card_image {
    border: 0;
}

.scheme_image_border {
    border-radius: 10px;
    /* height: 100%; */
    object-fit: cover;
}

.all-eventbtn {
    border: 1px solid #e44342;
    color: #e44342;
    padding: 5px 15px;
    background-color: #fff;
    cursor: pointer;
}

/* ----------------mobile-------------- */
@media screen and (max-width: 600px) {
    .inovation_headings {
        font-size: 30px;
    }

    .hero-img {
        padding: 0px 0px 0px 0px;
    }

    .schemes_sub_heading {
        font-size: 18px !important;
    }

}