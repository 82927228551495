.offering_card_image {
    width: 150px;
    border-radius: 50%;
    padding: 15px;
    background-color: #ffff;
}

.offering_card_title {
    font-size: 25px;
    color: #000000;
    font-weight: bold;
    text-align: center;
    padding-top: 40px;
}

.overall-name {
    font-size: 18px;
    color: #102e5d;
    font-weight: bold;
    margin-bottom: 8px;
}

.overall-image {
    margin: 10px 30px 10px 10px;
    width: 27px;
    height: auto
}

.overall-description {
    font-size: 18px;

}

.overall-container {
    margin-top: 20px;
    margin-left: 40px;
    margin-right: 40px;
}

.overall-responsive-container {
    margin-top: 20px;

}