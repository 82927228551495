.event_card {
  border: 1px solid #FDC2A6;
  height: 300px;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  padding: 0px;
}

.readmore_btn {
  background-color: #F8463F;
  border: solid 1px #F8463F;
  color: #fff;

}

.readmore_btn:hover {
  background-color: #fff;
  border: 1px solid #F8463F;
  color: #F8463F;

}

.readmore_btn a {
  color: #fff
}

.readmore_btn a:hover {
  color: #F8463F
}

.event_center_card {
  background-color: #FDCEB7;

}

.event_center_card .event_location {
  color: #F8463F;
}

.event_center_card .event_readmore {
  color: #fff;
}

.event_center_card .event_readmore:hover {
  color: #F8463F;
}

.event_title {
  color: #000000;
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.event_card_container {
  padding-top: 80px;

}

.text_black {
  color: #000000;
}

.event_icon {
  height: 16px;
  width: 16px;
}

.event_location {
  color: #ffa93c;
}

.event_readmore {
  color: #ffff;
}

.icn-event {
  display: flex;
  justify-content: center !important;
}

/* ------------------------------------------------ribbion--------------------------------------------- */
.ribbon1 {
  position: absolute;
  top: -6.1px;
  right: 0px;
  z-index: 100;
}

.ribbon1:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 53px solid transparent;
  border-right: 53px solid transparent;
  border-top: 10px solid #F8463F;
}

.ribbon1 span {
  position: relative;
  display: block;
  text-align: center;
  background: #F8463F;
  font-size: 14px;
  line-height: 1;
  padding: 12px 8px 10px;
  border-top-right-radius: 8px;
  width: 95px;
  color: #ffff;
}

.ribbon1 span:before,
.ribbon1 span:after {
  position: absolute;
  content: "";
}

.ribbon1 span:before {
  height: 6px;
  width: 6px;
  left: -6px;
  top: 0;
  background: #F8463F;
}

.ribbon1 span:after {
  height: 6px;
  width: 8px;
  left: -7px;
  top: 0;
  border-radius: 8px 8px 0 0;
  background: #C02031;
}