.main-button {
    /* line-height: 22px; */
    font-size: 15px;
    letter-spacing: 1px;
    font-weight: 600;
}

.main-button.round-button {
    border-radius: 50px;
    padding: 1px 17px 1px;
    /* border: solid 1px #f8463f; */
}



.main-button.squre-button {
    border-radius: 10px;
    padding: 7px 30px 7px;
}
.main-button.squre-button2 {
    border-radius: 10px;
    padding: 2px 30px 2px;
}

.button-big {
    /* line-height: 29px; */
    padding: 20px 30px 20px;
}

.button-primary {
    background: #a1513b;
    color: #ffffff;
    border: 2px solid #a1513b;
}

.button-primary:hover {
    color: #a1513b;
    background: none;
    border: 2px solid #a1513b;
}

.button-orange-inverted {
    background: transparent;
    color: #ff7f49;
    border: 2px solid #ff7f49;
}

.button-orange-inverted:hover {
    color: #fff;
    background: #ff7f49;
    border: 2px solid #ff7f49;
}

.button-orange:hover {
    background: transparent;
    color: #ff7f49;
    border: 2px solid #ff7f49;
}

.button-orange {
    color: #fff;
    background: #ff7f49;
    border: 2px solid #ff7f49;
}

.button-primary-inverted:hover {
    background: #a1513b;
    color: #ffffff;
    border: 2px solid #a1513b;
}

.button-primary-inverted {
    color: #a1513b;
    background: none;
    border: 2px solid #a1513b;
}

.button-secondary {
    background: #ffa93c;
    color: #ffffff;
    border: 2px solid #ffa93c;
}

.button-secondary:hover {
    color: #a1513b;
    background: none;
    border: 2px solid #a1513b;
}

.button-secondary-inverted:hover {
    background: #ffa93c;
    color: #ffffff;
    border: 2px solid #ffa93c;
}

.button-secondary-inverted {
    color: #ffa93c;
    background: none;
    border: 1px solid #f4f4f4;
    box-shadow: 0px 10px 10px rgb(0 0 0 / 20%);
}