.team_image{
  border-radius:10px ;
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.mentor-names{
  font-size: 16px;
  letter-spacing:1px ;
  color: #000000;
  font-weight: bold;
  text-align: center;
}
.mentor-designation{
  font-size: 14px;
  letter-spacing:1px ;
  text-align: center;
  color: #000;
  font-weight: bold;

}
.mentor-topics{
text-align: center;
letter-spacing:1px ;
}
.mentor-card{
  border: solid 1px rgb(228, 228, 228);
  height: 100%;
  margin-left: 4px;
  margin-right:4px ;
  border-radius: 5px;
}
.mentor-card-inner{
  padding: 30px 15px;
}