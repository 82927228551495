.home_faq_outer {
    border: 1px solid #dedede;
    direction: ltr;
    text-align: left;
    border-radius: 8px;
    text-align: center;
    color: #777777;
    width: 90%;
    min-height: 450px;
    max-height:450px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    background-color: #fff;
}

.home_faq_inner {
    padding: 68px 30px 45px;


}

.home_faq_questions {
    color: #444444;
    margin-bottom: 30px;
    height:150px
}

.faq_question {
    font-weight: 600;
}