/* @import url("https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i|Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i|Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i"); */
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/*font-family: 'Nunito', sans-serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Poppins', sans-serif;*/


@import url("font-awesome.css");
@import url("flaticon.css");
@import url("animate.css");
@import url("hover.css");
@import url("owl.css");
@import url("custom-animate.css");

/*** 

====================================================================
	Reset
====================================================================

 ***/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

:root {
    --swiper-theme-color: #000;
}

#root {
    overflow: hidden;
}

.alert-enter {
    opacity: 0;
    transform: scale(0.9);
}

.alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.alert-exit {
    opacity: 1;
}

.alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

#body {
    margin-top: 115px;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.common-sections {
    padding: 80px 0px 80px;
}


/*** 

====================================================================
	Global Settings
====================================================================

 ***/

body {
    font-size: 15px;
    color: #777777;
    line-height: 1.8em;
    font-weight: 400;
    background: #ffffff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    -webkit-font-smoothing: antialiased;
    /* font-family: "Nunito", sans-serif; */
    font-family: 'Barlow';
    letter-spacing: 1px;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: #ffa93c;
}

.no-padding {
    padding: 0px !important;
}

button:focus {
    outline: none;
}

.btn,
a:hover,
a:focus,
a:visited {
    text-decoration: none;
    outline: none;
}

.btn:focus {
    box-shadow: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    position: relative;
    font-weight: normal;
    margin: 0px;
    background: none;
    line-height: 1.6em;
    /* font-family: "Open Sans", sans-serif; */
    font-family: 'Barlow';
    letter-spacing: 1px;
}

textarea {
    overflow: hidden;
}

p,
.text {
    position: relative;
    line-height: 1.8em;
}

.strike-through {
    text-decoration: line-through;
}

.auto-container {
    position: static;
    max-width: 1400px;
    padding: 0px 15px;
    margin: 0 auto;
}

.head-container {
    position: static;
    max-width: 1400px;
    padding: 0px 15px;
    margin: 0 auto;
}

.page-wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    min-width: 300px;
}
.hero-overlay-text-grad {
    color: rgb(167, 9, 9);
    background: linear-gradient(90deg, rgb(177, 175, 199) 0%, rgb(212, 189, 189) 18%, rgb(223, 222, 222) 35%, rgb(226, 226, 226) 64%, rgb(216, 197, 197) 80%, rgb(228, 202, 202) 92%, rgb(226, 211, 211) 100%);
    height: 50px;
    font-size: 28px;
}

.scroll-to-top {
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 50px;
    height: 50px;
    color: #ffffff;
    font-size: 24px;
    text-transform: uppercase;
    line-height: 65px;
    text-align: center;
    z-index: 9999999999999;
    cursor: pointer;
    background: #ffa93c;
    display: block;
    border: 2px solid #ffa93c;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}

.scroll-to-top:hover {
    color: #ffa93c;
    background: #ffffff;
    border-color: #ffa93c;
}


/*List Style One*/

.list-style-one {
    position: relative;
}

.list-style-one li {
    position: relative;
    color: #555555;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
}

.list-style-one li span {
    position: relative;
    color: #222222;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
}

ul,
li {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.theme-btn {
    display: inline-block;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.grey-bg {
    background-color: #f1f2f3;
}


/* ////////////////////////////////////////////////////////////////////////////////// */

#kied-hero-section {
    position: relative;
    height: calc(108vh - 180px);
}

.hero-overlay {
    position: absolute;
    /* background: #00000050; */
    height: 100%;
    bottom: 0;
    /* display: flex; */
    /* align-items: center; */
    width: 100%;
}

video {
    object-fit: cover;
}

.hero-title {
    color: white;
    text-align: left;
    font-weight: normal;
    line-height: 1.2em;
    /* font-family: "Open Sans", sans-serif; */
    font-family: 'Barlow';
    letter-spacing: 1px;
    padding-bottom: 15px;
    margin-bottom: 100px;
}

.hero-text {
    font-size: 17px;
    line-height: 1.8em;
    text-align: justify;
    color: white;
    margin-bottom: 30px;
}

.hero-button {
    font-size: 17px;
    line-height: 1.8em;
    text-align: justify;
    color: white;
}

.hero-button-icon {
    font-size: 30px;
}


/* ///////////////////////////////////////////////////////////////////// */

#kied-services-section {
    position: relative;
    padding: 80px 0px 80px;
    padding-right: 15px;
    padding-left: 15px;
    z-index: 1;
}

.footer {
    background-color: #f6f6f6;
    color: #7f8497;
    font-size: 15px;
}

.footerSub {
    border-top: 1px solid #d4d4d4;
}

.footersub_label {
    text-align: center;
    color: #7f8497;
    font-size: 14px;
}

.bannerImage {
    height: 150px;
    width: 150px;
}

.about_icon {
    font-size: 60px;
    color: #ffa93c;
}

.banner_image {
    /* background-image: url("../../src/assets/images/resourses/about_banner.png");
    background-size: 100% 100%; */
    background: rgb(21, 20, 34);
    background: linear-gradient(90deg, rgba(21, 20, 34, 1) 0%, rgba(24, 18, 37, 1) 18%, rgba(14, 13, 13, 1) 35%, rgba(15, 20, 20, 1) 64%, rgba(32, 27, 27, 1) 80%, rgba(52, 42, 42, 1) 92%, rgba(71, 67, 67, 1) 100%);
    max-height: 600px;

}

.banner_big {
    min-height: 200px;
}

.aboutpage_images {
    padding: 20px;
    display: flex;
    justify-content: center;
}

.aboutimage {
    height: 100%;
}

.text {
    text-align: justify;
    font-family: inherit;
    padding-top: 20px;
    line-height: 1.8rem;
}

.about_content {
    padding-top: 180px;
}

.about_kied_image {
    padding-top: 55px;
    margin-left: 49px;
}

.bannerContainer {
    margin-left: 8px;
    margin-right: 0;
    margin: 10px;
}

.about_kied_Text {
    padding-bottom: 20px;
}

.text_about {
    color: #000;
    text-align: justify;
    font-family: inherit;
    padding-top: 20px;
    line-height: 1.8rem;
}

.text_banner {
    color: #fff;
    font-size: 40px;
    /* font-weight: 700; */
    letter-spacing: 2px;
    line-height: 1.3em;
}

.about_kied {
    margin-top: 78px;
}

.formtitle {
    color: #a1513b;
    /* margin-top: 30px; */
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: bold;
}

.heading-line {
    border-bottom: 1px solid #ececec;
}

.curser-pointer {
    cursor: pointer;
}

.submit_sol_form>label.formstyle {
    padding: 0;
    font-size: 18px;
}

.footer-list li {
    list-style: none;
    padding-bottom: 25px;
}

.alert-login {
    position: relative;
    padding: 0.75rem 0rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    background-color: #fff;
    text-align: left;
}

.alert-error {
    color: #ff4b2b;
    font-size: 18px;
}

.alert-error-icon {
    height: 20px;
}

.alert-success {
    color: #339b4b;
    background-color: #fff;
    font-size: 18px;
}

.success-icon {
    height: 20px;
}

.common-padding {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-top {
    padding-top: 20px
}

.padding-bottom {
    padding-bottom: 20px;
}

.banner-stats-container {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #000;
}

.banner-stats {
    border-right: 2px solid orange;
}

.stats-value {
    font-size: 20px;
    color: #fff;
    font-weight: 700;
}

.stats-heading {
    font-size: 17px;
    color: #fff;
}

.pointer {
    cursor: pointer;
}

.profilei {

    padding: 2px 10px;
    border-radius: 50%;
    color: #fff;
    background-color: #a1513b;
    cursor: pointer;
    text-transform: uppercase;
}

.profilei:hover {
    border: 1px solid #a1513b;
    padding: 1px 11px;
    border-radius: 50%;
    color: #a1513b;
    background-color: #fff;
    cursor: pointer;
    text-transform: uppercase;
}

.required {
    border-left: 2px solid red !important;
}
.home_events_carousel{
    margin-top: 74px;
}