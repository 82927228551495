.table-head {
    background-color: brown;
    color: #fff;
    border: 1px solid black;

}

tr {
    border: 1px solid black;
}

th {

    padding: 10px;
    text-align: center;
}

td {
    border: 1px solid black;
    padding: 8px;
    font-size: 13px;
    color: #000000;
}

.career-description {
    font-family: 'Barlow';
}

.career-description ul {
    /* font-family: 'Barlow'; */
    padding-left: 15px;
}

.career-description ul li {
    font-family: 'Barlow';
    padding-left: 10px;
}

.table-button {
    padding: 10px 15px;
    color: #fff;
    background-color: #ffa93c;
    border-radius: 5px;
    border: 1px solid #ffa93c;
    margin-top: 20px !important;
}
.table-button:hover  {
    padding: 10px 15px;
    color: #ffa93c !important;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #ffa93c !important;
}
.table-button-parent a :hover{
color: #ffa93c;
}

/* td, a{
    color:#000000
} */