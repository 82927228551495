@media screen and (max-width: 600px) {
  .edc-program-hero {
    margin-top: 20px !important;

  }

  .edc-program-img-new {
    display: flex;
    justify-content: center;
  }

  .inovation_headings {
    font-size: 25px;
  }

  .yuva-details ul li {
    text-align: left !important;
    font-size: 15px !important;
  }

  .detail-description {
    text-align: left;
  }

  .detail-description ul li {
    font-size: 15px !important;
  }

  .white-bg {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 20px !important;
  }

  .sectional-spaces {
    margin-top: 20px;
    padding: 20px 10px !important;
  }

  .listing-details {
    margin-bottom: 20px !important;
  }

}

/* -----------------------------normal-screen--------------- */
.sectional-spaces {
  margin-top: 100px;
  padding: 50px 20px;
}

.yuva-details ul li {
  margin-bottom: 30px;
  text-align: justify;
  font-size: 20px;
  color: #000;
  list-style: none;
}

.yuva-program-details {
  font-size: 25px;
  color: #000;
}

.listing-details {
  margin-bottom: 40px;
}

.white-bg {
  background-color: #fff;
  padding: 50px 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.Scrolling-button {

  color: #ffa93c;
  border: solid 1px #ffa93c;
  background-color: #fff;
  /* padding: 16px 32px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 10px;
  font-size: 18px;
  height: auto;
  width: 350px;
  /* min-width: 300px; */
}

.activeh {
  background-color: #ffa93c;
  border: solid 1px #ffa93c;
  color: #ffff;
  padding: 16px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 10px;
  font-size: 18px;
  height: auto;
  width: 350px;

}

.button-section {
  margin-top: 50px;
  margin-bottom: 50px;
}

.image-paragraph {
  font-size: 16px;
  color: #000;
  margin-top: 2px;
}

.screenshot {
  margin-top: 50px;
}
.screenshot-img{
  width: 50%;
  height: 50%;
}
.table-inner th tr{
  padding: 2px 2px !important;
}
/* ------------mobile-------------------- */
@media screen and (max-width: 600px) {
  .screenshot-img{
    width: 100%;
    height: 100%;
  }
}