.news-section>.sec-title {
    position: relative;
    margin-bottom: 80px;
}
.greyBackground{
    background-color:#EFEFEF ;
    background-image: linear-gradient(rgb(241, 245, 250), #EFEFEF, rgb(241, 245, 250))
}
.backgroundGrey{
    background-color:#EFEFEF ;
    background-image: linear-gradient(rgb(241, 245, 250), #EFEFEF, rgb(241, 245, 250))  
}