.schemes_heading{
    font-size: 30px;
    color: #000;
    line-height: 3px;
    line-height: 45px;
}
.schemes_sub_heading{
    font-size: 20px;
    color: #000;
}
.Schemes_heading_description{
    font-size: 18px;
    color: #000000;
    text-align: justify;
}
/* .Schemes_heading_description li{
    font-size: 18px;
    color: #000000;
    text-align: justify;
    margin-top: 15px;
} */

.Schemes_heading_description>li {
    /* list-style: none;  */
    /* color: #FFA93C !important; */
  }
  .Schemes_heading_description>li>p {
    /* list-style: none;  */
    color:#000 !important;
  }
  .schemes-listing-card{
    padding: 30px ;
    background-color: #fff;
    height: 100%;
  }

  .schemes-fee{
    font-size: 22px;
    color: #000;
    font-weight: 500;
    border: #E24948 solid 1px;
    padding: 30px 30px;
    width: 70%;
    background-color: bisque;
  }
  .athority-section-list>ul>li{
    list-style: none !important;
  }
  .fess-discription{
    font-size: 18px;
    color: #000;
  }
  /* .Schemes_heading_description  li::before {
    content : "\2022";
    padding-right: 10px; 
    font-size: 28px;
    list-style: red;
  } */
  .authority-details{
    font-size: 18px;
    color: #000;
  }
.authority-icon{
    font-size: 30px;
    color: #FFA93C;
    padding-bottom: 5px;
}
.authority-address>ul>li{
    list-style: none;
}
.authority-txt{
    font-size: 18px;
    color: #000;
    margin-left: 3px;
}
.schemes_download{
    color: #E24948;
    font-size: 15px;
    font-weight: bold;
    border-radius: 5px;
}
.schemes_download :hover{
    color: #E24948;
    font-size: 15px;
    font-weight: bold;
    background-color: #d9d9d9;
    border-radius: 5px;
}
.download_description{
    font-size:15px ;
    color: #777777;
    font-family: sans-serif;
}
.arrow_icon{
    font-size: 40px;
}
.sub_list li{
    margin-left: 40px;
    list-style-type:circle;
}
.Schemes_new_discription{
    background-color: #ffe4e1;
    padding: 20px 40px;
    border-radius: 5px;
    border-left: 4px solid #FFA93C;
}
.schemes-list ul li{
 text-align: left !important;
}
/* -----------------------mobile----------------- */

@media screen and (max-width: 600px){
  .text_banner{
    font-size: 30px;
    font-weight: 700;
  }
  .athority-section{
    margin-top: 30px;
  }
}