



/* ...............mobile.................... */
@media only screen and (max-width: 600px){

}
.ekm-campus{
  object-fit: contain;
}