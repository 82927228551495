.payment-mainContainer{
  width: 300px;
  height: 100%;
  max-height: 500px;
  border: 1px solid #ff7f49;
  border-radius: 5px;
  padding: 20px;
}
.payment_title_color{
  color: #a1513b;
}