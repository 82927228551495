.dashletter {
  font-size: 90px;
  text-transform: capitalize;
  border: 7px solid #fff;
  border-radius: 50%;
  padding: 15px 30px;
  background-color: rgb(114, 113, 113);
  color: #fff;
}

.dashlettermob {
  font-size: 60px;
  text-transform: capitalize;
  border: 7px solid #fff;
  border-radius: 50%;
  padding: 15px 20px;
  background-color: rgb(114, 113, 113);
  color: #fff;
}

.dashboardUser {
  font-size: 22px;
  color: #000;
  font-weight: bold;
  border-radius: 5px;

}
.uc-accepted {
  color:rgb(8, 199, 135)

}
.uc-rejected {
  color:rgb(226, 42, 29)

}
.uc-submitted {
  color:rgb(253, 195, 5)

}

.availButton {
  height: auto;
  border: 0;
  font-size: 10px;
  width: auto;
  padding: 5px 20px;
}

.dashboardRole {
  font-size: 16px;
}

.subBannerProfile {
  color: #c06255;
  background-color: #fff;
  font-size: 20px;
  font-weight: bold;
}

.subBannerComplete {
  color: #000;
  font-size: 20px;
  font-weight: bold;
}

.dashform {
  max-width: 1000px;
}

.dashdoc {
  font-size: 22px;
  color: #c06255;
}

.dashdocbox {
  border: 1px solid rgb(162, 159, 159);
  border-radius: 5px;
  box-shadow: 10px 10px 10px lightblue;
}

.dashdocbox-outer {
  margin-top: 40px;
}

.dashdocname {
  color: #000;
}

.header-border-form {
  height: 5px;
  background-color: #a1513b;
}

.dashDataBox {
  border: 1px solid rgb(162, 159, 159);
  border-radius: 5px;
  margin-top: 30px;
  /* padding: 0 5px 0 50px; */
  margin-bottom: 30px;
  /* min-width: 150px; */
  box-shadow: 10px 10px 10px lightblue;
}

@media print {
  .dashprintContainer {
    margin: 200px;
    width: calc(100% - 400px);
  }
}