.main-footer {
    position: relative;
    background-color: #242228;
}

.main-footer .button-box {
    position: relative;
    top: -28px;
    text-align: center;
}

.main-footer .widgets-section {
    position: relative;
    padding: 80px 0px 30px;
}

.main-footer .footer-column {
    position: relative;
    display: inline-block;
    /* float: left; */
    /* width: 20%; */
    margin-bottom: 30px;
}

.main-footer .footer-column h2 {
    position: relative;
    color: #444444;
    font-size: 17px;
    font-weight: 600;
    line-height: 1.3em;
    margin-bottom: 15px;
    font-family: "Open Sans", sans-serif;
}

.main-footer .footer-column .footer-list {
    position: relative;
}

.main-footer .footer-column .footer-list li {
    position: relative;
    margin-bottom: 8px;
    text-align: center;
}

.main-footer .footer-column .footer-list li a {
    position: relative;
    color: #fff;
    font-size: 15px;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.main-footer .footer-column .footer-list li a:hover {
    color: #ffa93c;
}

.main-footer .footer-bottom {
    position: relative;
    text-align: center;
    padding: 35px 0px 35px;
    border-top: 1px solid #d4d4d4;
    background-color: #242228;
}

.main-footer .footer-bottom .logo {
    position: relative;
    margin-bottom: 20px;
}

.main-footer .footer-bottom .copyright {
    position: relative;
    color: #7f8497;
    font-size: 14px;
    margin-bottom: 16px;
}

.main-footer .footer-bottom .social-nav {
    position: relative;
}

.main-footer .footer-bottom .social-nav a {
    position: relative;
    margin: 0px 9px;
    color: #7f8497;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.main-footer .footer-bottom .social-nav a:hover {
    color: #ffa93c;
}

.clearfixf {
    display: flex;
    justify-content: space-between;
    
}